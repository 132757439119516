<template>
    <div class="ai-container" ref="videoContainer">
        <div class="title">AI加持，效率再获提升</div>
        <div class="top-desc">
            <AnimationAI :animation="loaded"></AnimationAI>
            <img :src="arrowLine" alt="" class="line" />
            <div class="middle">3D</div>
            <img :src="arrowLine" alt="" class="line" />
            <AnimationAI :animation="!loaded"></AnimationAI>
        </div>
        <div class="card-container">
            <div class="ai-card ai-card1">
                <video
                    ref="leftVideoRef"
                    class="video"
                    :src="leftVideo"
                    muted
                    playsinline
                    preload="auto"
                    @ended="onLeftEnd"
                ></video>
                <div class="bottom-text">
                    <div class="title">创意</div>
                    <div class="desc">辅助灵感</div>
                </div>
            </div>
            <div class="ai-card ai-card2">
                <img :src="aiImg" alt="" />
                <div class="bottom-text"></div>
            </div>
            <div class="ai-card ai-card3">
                <video
                    ref="rightVideoRef"
                    class="video"
                    :src="rightVideo"
                    muted
                    playsinline
                    preload="auto"
                    @ended="onRightEnd"
                ></video>
                <div class="bottom-text">
                    <div class="title">营销推广</div>
                    <div class="desc">AI增强出图，辅助产品营销</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { onMounted, reactive, ref } from 'vue';
    import aiImg from './ai.png';
    import lineImg from './line.png';
    import arrowLine from './arrowLine.png';
    import leftVideo from './leftVideo.mp4';
    import rightVideo from './rightVideo.mp4';
    import AnimationAI from '../../AnimationAI.vue';

    const leftVideoRef = ref(null);
    const rightVideoRef = ref(null);

    const loaded = ref(false);

    onMounted(() => {
        leftVideoRef.value.play();
        loaded.value = true;
        addSlideInUp('.ai-card');
        addSlideInUp('.ai-card1', { delay: 0.1 });
        addSlideInUp('.ai-card2', { delay: 0.2 });
        addSlideInUp('.ai-card3', { delay: 0.3 });
    });

    const onLeftEnd = () => {
        rightVideoRef.value.play();
        loaded.value = false;
    };
    const onRightEnd = () => {
        leftVideoRef.value.play();
        loaded.value = true;
    };
</script>
<style lang="less" scoped>
    .ai-container {
        color: #fff;
        font-family: HarmonyOS Sans SC;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 80px;
        .title {
            font-size: 48px;
            font-weight: 400;
            line-height: 56.26px;
        }

        .top-desc {
            font-family: Montserrat;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 1010px;
            margin-top: 40px;
            .middle {
                font-size: 24px;
                color: #ababab;
            }
            .line {
                width: 370px;
            }
        }

        .card-container {
            width: 1280px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .ai-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            .video {
                width: 377px;
            }
            img {
                width: 377px;
                margin-top: -85px;
            }

            .bottom-text {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .title {
                margin-top: 30px;
                font-size: 24px;
                font-weight: 500;
                line-height: 28.13px;
            }
            .desc {
                margin-top: 10px;
                font-size: 16px;
                font-weight: 400;
                line-height: 18.75px;
                color: #979797;
            }
        }
    }
</style>
