<script setup lang="ts">
    import { routeMapper } from '~~/composables/useRoutesMapper';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import { ref, reactive, onMounted, onBeforeMount, computed } from 'vue';
    import homeSwipperVideo from '@assets/Video/home/new_first_screen.mp4';

    import i18n from '@/locales';

    const isCN = ifCNLocale();
    const { t } = i18n.global;
    const isMobile = ref(false);
    const topVisible = ref(false);

    const showVideoSwipper = ref(false);

    onMounted(() => {
        isMobile.value = matchMobile();

        showVideoSwipper.value = true;

        window.addEventListener('scroll', appearBackTop);
    });

    const appearBackTop = () => {
        // console.log('window.scrollY', window.scrollY);
        // console.log('window.innerHeight', window.innerHeight * 2);
        if (window.scrollY >= window.innerHeight * 2) {
            // console.log('topVisible.value = true;', (topVisible.value = true));
            topVisible.value = true;
        }
        if (window.scrollY < window.innerHeight * 2) {
            // console.log('topVisible.value = true;', (topVisible.value = true));
            topVisible.value = false;
        }
    };
</script>

<template>
    <div class="home-container">
        <!-- 首页轮播 -->
        <div class="home-ant-carousel">
            <video :src="homeSwipperVideo" muted loop playsinline autoplay preload="auto"></video>
            <div class="home-swipper-mask"></div>
            <div class="text-wrapper" v-if="showVideoSwipper">
                <div class="text-content global-limited-1360px-container">
                    <div class="title">打造数字引擎·驱动时尚未来</div>
                    <div class="subTitle">
                        利用生成式人工智能和实时模拟的前沿研究，Style3D为从事时尚、动画和游戏行业的<br />企业和个人提供数字时尚软件、硬件、云平台和全面服务。
                    </div>
                </div>
            </div>
        </div>

        <!-- 全链路 -->
        <CommonComponentsHomeNewProcedure />
        <!-- Banner -->
        <!-- <CommonComponentsHomeVideoSwipper /> -->

        <div class="global-limited-1360px-container">
            <!-- 产品目录 -->
            <CommonComponentsHomeProductNewDetails />
            <!-- AI -->
            <CommonComponentsHomeNewAI />
            <!-- 地图 -->
            <CommonComponentsHomeNewMapLink />
            <!-- 合作伙伴 -->
            <CommonComponentsHomeNewPartners />
        </div>
        <div class="gsap-pin-target">
            <CommonComponentsFooter />
        </div>
    </div>
</template>

<style lang="less" scoped>
    .home-container {
        background-color: #000000;
        width: 100%;

        :deep(.home-ant-carousel) {
            video {
                height: 75vh;
                width: 100%;
                object-fit: cover;
            }
        }
    }
</style>
<style lang="less">
    .bg-light-gray {
        overflow: hidden;
        background-color: #262626;
        padding-bottom: .convert(100px) [ @vh];
        @media (min-width: 1440px) {
            padding-bottom: .convert(30px) [ @vh];
        }
        @media (min-width: 1920px) {
            padding-bottom: .convert(10px) [ @vh];
        }
    }

    .solution-arrow-up,
    .solution-arrow-down {
        margin: .convert(100px) [ @vw] 0;
        height: .convert(194px) [ @vw];
        @media (max-width: @screen-mobile-width) {
            margin: 40px 0;
            height: 50px;
        }
    }

    .home-swipper-mask {
        z-index: 1;
        position: absolute;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .home-ant-carousel {
        position: relative;
        // width: min(94.44444444vw, 1360px);
        .text-wrapper {
            z-index: 2;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
        }
        .text-content {
            font-family: 'HarmonyOS Sans SC';
            color: #fff;
            .title {
                font-size: 70px;
                font-weight: 500;
                line-height: 63px;
                color: #fff;
                filter: drop-shadow(0 0 1rem #00000022);
            }
            .subTitle {
                margin-top: 24px;
                color: #fff;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                filter: drop-shadow(0 0 1rem #00000022);
            }
        }
    }
</style>
