<template>
    <div class="procedure-container">
        <!-- 标题 -->
        <div class="procedure-title">
            <template v-if="showTextVisible">
                <div class="title">全链路赋能时尚产业</div>
                <div class="desc">降低实物样衣损耗，提升设计效率，让决策更高效</div>
            </template>
        </div>
        <!-- 箭头 -->
        <div class="procedure-arrow">
            <div class="arrow" @click="toRight">
                <img :src="LeftArrow" alt="" />
            </div>
            <div class="arrow right" @click="toLeft">
                <img :src="RightArrow" alt="" />
            </div>
        </div>
        <div id="slide-container" class="slide-container">
            <!-- 链路 -->
            <div class="procedure-line padding-h">
                <img :src="CardLineImg" alt="" />
            </div>
            <!-- 卡片 -->
            <div class="padding-h">
                <div class="card-container">
                    <div
                        class="procedure-card"
                        :class="`procedure-card-${itemIndex}`"
                        v-for="(item, itemIndex) in procedureList"
                        :key="itemIndex"
                    >
                        <img :src="item.img" alt="" />
                        <div class="card-text">
                            <div class="title1">{{ item.title }}</div>
                            <div class="desc1">
                                {{ item.desc }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { onMounted, reactive, ref } from 'vue';
    import CardLineImg from './CardLine.png';
    import Card1Img from './Card1.png';
    import Card2Img from './Card2.png';
    import Card3Img from './Card3.png';
    import Card4Img from './Card4.png';
    import Card5Img from './Card5.png';
    import LeftArrow from './LeftArrow.png';
    import RightArrow from './RightArrow.png';
    import { addSlideInDown, addSlideInUp, addFadeIn } from '~~/composables/useScrollTrigger';

    let slideContainer;
    const showTextVisible = ref(false);

    onMounted(() => {
        showTextVisible.value = true;
        slideContainer = document.getElementById('slide-container');
        addSlideInUp('.procedure-card-0', { delay: 0.1 });
        addSlideInUp('.procedure-card-1', { delay: 0.2 });
        addSlideInUp('.procedure-card-2', { delay: 0.3 });
        addSlideInUp('.procedure-card-3', { delay: 0.4 });
        addSlideInUp('.procedure-card-4', { delay: 0.5 });
    });
    const toRight = () => {
        slideContainer.scrollTo({
            left: -1100,
            behavior: 'smooth',
        });
    };
    const toLeft = () => {
        slideContainer.scrollTo({
            left: 1100,
            behavior: 'smooth',
        });
    };

    const procedureList = reactive([
        {
            img: Card1Img,
            title: '设计',
            desc: '精准地生成设计素材，帮助设计师提升创造、修改等效率。对比传统商品新款开发、设计评审等，时间提效至少数倍。',
        },
        {
            img: Card2Img,
            title: '研发',
            desc: '3D柔性仿真，替代样衣和款式验证。 节省数轮实物打样，大幅节约实物样衣损耗。',
        },
        {
            img: Card3Img,
            title: '协同',
            desc: '帮助企业内部跨部门、企业间，高效沟通协作，实时跟进款式开发状态，云端在线盘货看款，提升远程决策效率。',
        },
        {
            img: Card4Img,
            title: '营销',
            desc: '高清渲染Look图以及走秀视效，推款上新随意又简单，AI工具加成，模特试穿效果真实，低成本高质量推广。',
        },
        {
            img: Card5Img,
            title: '管理',
            desc: '可对服装资产管理、分享，以及有丰富的获客工具，沉淀3D服装资产，便于企业的新款设计、研发、推广等。',
        },
    ]);
</script>
<style lang="less" scoped>
    .procedure-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-family: HarmonyOS Sans SC;
        margin-top: 80px;
        width: 100%;
        overflow: hidden;
    }
    .procedure-title {
        min-height: 100px;
        .title {
            font-size: 48px;
            font-weight: 400;
            line-height: 56.26px;
            text-align: center;
        }
        .desc {
            font-size: 24px;
            font-weight: 400;
            line-height: 28.13px;
            text-align: center;
            color: #ffffff99;
            margin-top: 20px;
        }
    }
    .procedure-arrow {
        display: flex;
        margin-top: 40px;
        margin-bottom: 40px;
        .arrow {
            width: 44px;
            height: 44px;
            background-color: #626262;
            border-radius: 90px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
                opacity: 0.6;

                &:hover {
                    opacity: 1;
                }
            }
        }
        .right {
            margin-left: 20px;
        }
    }
    .slide-container {
        width: 100%;
        overflow-x: auto;
        // margin-top: 40px;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
    .padding-h {
        display: inline-block;
        padding-left: calc((100vw - 1360px) / 2);
        padding-right: calc((100vw - 1360px) / 2);
    }
    .procedure-line {
        img {
            width: 1984px;
        }
    }

    .card-container {
        display: flex;

        .procedure-card:last-child {
            margin-right: 0px;
        }
        .procedure-card {
            margin-right: 90px;
            border-radius: 30px;
            position: relative;

            &:nth-child(2n + 1) {
                margin-top: 80px;
            }
            img {
                width: 400px;
            }
            .card-text {
                padding: 30px;
                background: #252528;
                border-radius: 0 0 30px 30px;
                .title1 {
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 42.19px;
                }
                .desc1 {
                    font-size: 16px;
                    font-weight: 350;
                    line-height: 24px;
                    color: #979797;
                    margin-top: 10px;
                    height: 72px;
                }
            }
        }
    }
</style>
